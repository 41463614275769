import {styled} from "@mui/material";
import {Chip} from "@mui/joy";

export const DropDownContainer = styled("div")({
    width: "100%",
    maxWidth: "400px",
    display: "flex",
    justifyContent: "space-between",
    margin: "25px 0",
    "@media (max-width: 500px)": {
        maxWidth: "unset",
        flexDirection: "column",
        alignItems: "flex-start",
    }
})

export const DropDownButton = styled(Chip)({
    background: "rgba(49,59,68,0.47)",
    textTransform: "unset",
    color: "#ffffff",
    fontSize: "1rem",
    margin: "5px 0",
    "&:hover": {
        background: "rgba(49,59,68,0.75)",
    },
})
