import React, {useState, useRef, useEffect} from 'react';
import Layout from "../../components/Layout";
import {Form, FormButton, FormInput, TextArea} from "./styled";
import {Title} from "../../components/Title";
import {MainStore} from "../../store";
import {observer} from "mobx-react-lite"
import {Button, Box} from '@mui/material';
import {dropDownValues} from "./variables";
import {RWebShare} from "react-web-share";
import Dropdown from "../../components/Dropdown";
import CircularProgress from '@mui/material/CircularProgress';

const Home = observer(() => {
    const store = new MainStore()
    const [text, setText] = useState("")
    const [data, setData] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentId, setCurrentId] = useState(0)
    const [loading, setLoading] = useState(false)
    const open = Boolean(anchorEl);
    const textInput = useRef(null);
    const [textArea, setTextArea] = useState("")

    useEffect(() => {
        if (text.length > 0) {
            textInput.current.focus();
        }
    }, [textInput, text]);

    const onSubmit = async (e, text) => {
        e.preventDefault()
        setData([])
        setLoading(true)
        const result = await store.getData(text)
        setLoading(false)
        setData(prevState => prevState = result)
        setTextArea(result.choices[0].text)
        setText("")
    }

    const onLoadData = (data) => {
        const fileData = JSON.parse(JSON.stringify(data));
        const blob = new Blob([fileData], {type: "text/plain"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "simpleist.doc";
        link.href = url;
        link.click();
    }

    return (
        <Layout>
            <Title sx={{textAlign: "left"}}>
                Select a choice from the options below
            </Title>
            <Dropdown dropDown={dropDownValues}
                      setCurrentId={setCurrentId}
                      setAnchorEl={setAnchorEl}
                      setText={setText}
                      text={text}
                      currentId={currentId}
                      open={open}
                      anchorEl={anchorEl}/>
            <Form action="" onSubmit={(e) => onSubmit(e, text)}>
                <FormInput variant="standard"
                           value={text}
                           label="What do you want to ask? for eg - Packing guide for travel to New York in April with sub-categories"
                           inputRef={textInput}
                           onChange={(e) => setText(e.target.value)}/>
                <FormButton variant="contained" type="submit">Submit</FormButton>
            </Form>

            {
                loading === true
                    ?
                    <Box sx={{display: "flex", alignItems: "center", padding: "20px 0 0"}}>
                        <h1 style={{margin: "0", color: "#313b44"}}>Your request is being processed...</h1>
                        <CircularProgress sx={{color: "#00c855", margin: "0 15px"}}/>
                    </Box>
                    :
                    <></>
            }

            <TextArea size="md"
                      variant="soft"
                      onChange={(e) => setTextArea(e.target.value)}
                      value={textArea}/>
            <RWebShare
                data={{
                    text: data.choices ? JSON.parse(JSON.stringify(data.choices[0].text)) : "",
                    url: "https://simplelist.ai/",
                    title: "SimpleList.ai, Simple, Organized!",
                }}
                onClick={() => console.log("shared successfully!")}
            >
                <Button variant="outlined">Share</Button>
            </RWebShare>
            <Button variant="outlined" disabled={data.length === 0}
                    onClick={() => onLoadData(data.choices[0].text)}>
                Download
            </Button>
            <p>Please Share your feedback.<a href="http://bitly.ws/A9sA">Help us improve</a></p><br/><br/>
        </Layout>
    );
});

export default Home;
