import {Button, Paper, styled, TextField} from "@mui/material";

import Textarea from "@mui/joy/Textarea";
export const Form = styled('form')({
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    "@media (max-width: 500px)": {
        justifyContent: "space-between",
    }
});

export const FormButton = styled(Button)({
    background: "#00c855",
    color: "#ffffff",
    margin: "0 20px",
    "&:hover": {
        background: "#008c3a",
    },
    "@media (max-width: 500px)": {
        margin: 0,
    }
});

export const FormInput = styled(TextField)({
    width: "100%",
    maxWidth: "calc(100% / 1.5)",
    color: "#313b44",
    "& ::after": {
        borderBottom: "2px solid #00c855 !important"
    },
    "& ::before": {
        borderBottom: "2px solid #313b44 !importnat"
    },
    "& label": {
        color: "#313b44",
    },
    "& label.Mui-focused": {
        color: "#00c855"
    }
});

export const PagePaper = styled(Paper)({
    minHeight: "200px",
    margin: "20px 0",
    padding: "20px",
    background: "#e5e5e5"
})

export const TextArea = styled(Textarea)({
    margin: "20px 0",
    background: "#d5d5d5",
    minHeight: "200px",
    overflowY: "auto",
    "--Textarea-focusedHighlight": "none",
    color: "#313b44 !important",
})
