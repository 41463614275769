import {makeAutoObservable} from "mobx";
import  { Configuration, OpenAIApi } from "openai";
import {toast} from "react-toastify";

const configuration = new Configuration({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);
export class MainStore {

    data = {}

    constructor() {
        makeAutoObservable(this)
    }

    async getData(text) {
        try {
            const response = await openai.createCompletion({
                model: "text-davinci-003",
                temperature: 0.7,
                max_tokens: 1048,
                top_p: 1,
                prompt: text,
                frequency_penalty: 0,
                presence_penalty: 0,
            });
            toast.success("Success result!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return response.data
        } catch (e) {
            return toast.error(e.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    }
}