import {styled} from "@mui/material";

export const Title = styled('h1')({
    fontSize: 24,
    color: "#313b44",
    margin: "20px 0",
    "@media (max-width: 500px)": {
        fontSize: "20px"
    }
})
