import {Container, styled} from "@mui/material";

export const NavMenuWrapper = styled("div")({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "20px 0",
    borderBottom: "1px solid rgb(152, 152, 157)",
    "@media (max-width: 768px)": {
        paddingTop: 0,
    }
});

export const NavMenuContainer = styled(Container)((props) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
}));

export const NavItemsWrapper = styled("nav")((props) => ({
    width: "100%",
    maxWidth: "fit-content",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 768px)": {
        maxWidth: "unset",
        flexWrap: "wrap",
        justifyContent: "flex-end",
    }
}));

export const NavItem = styled("a")((props) => ({
    textDecoration: "none",
    color: `${props.fontWeight === "true" ? "rgb(0, 112, 124)" : "rgb(97, 97, 101)"}`,
    marginLeft: "15px",
    cursor: "pointer",
    fontWeight: `${props.fontWeight === "true" ? "bold" : "unset"}`,
    transition: "all 0.2s",
    "&:hover": {
        color: "rgb(152, 152, 157)",
    },
    "@media (max-width: 768px)": {
        margin: "10px 0 10px",
        width: "100%",
        textAlign: "right",
    }
}))