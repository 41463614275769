import React from 'react';
import {DropDownButton, DropDownContainer} from "./styled";
import {KeyboardArrowDown} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import {MenuItem} from "@mui/material";

const Dropdown = ({dropDown, text, setText, setAnchorEl, setCurrentId, anchorEl, currentId, open}) => {
    const handleClick = (event, id, value) => {
        setCurrentId(id)
        setText(value[0].toUpperCase() + value.slice(1) + " ")
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChoseValue = (value) => {
        setText(text + value.toLowerCase() + " ")
        handleClose()
    }

    return (
        <DropDownContainer>
            {
                dropDown.map(button => {
                    return (
                        <div key={button.id}>
                            <DropDownButton
                                id={`${button.id}`}
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={(e) => handleClick(e, button.id, button.name)}
                                endDecorator={<KeyboardArrowDown />}
                            >
                                {button.name}
                            </DropDownButton>
                            <Menu
                                id={`${currentId}`}
                                anchorEl={anchorEl}
                                open={currentId === button.id && open}
                                onClose={handleClose}
                                sx={{
                                    top: "20px"
                                }}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {
                                    button.values.map((value, idx) => {
                                        return (
                                            <MenuItem key={idx}
                                                      onClick={() => handleChoseValue(value)}>{value}</MenuItem>
                                        )
                                    })
                                }
                            </Menu>
                        </div>
                    );

                })
            }
        </DropDownContainer>
    );
};

export default Dropdown;
