export const navMenu = [
    {
        id: 2,
        name: "Accessibility",
        title: "Accessibility",
        path: `/accessibility`
    },
    {
        id: 3,
        name: "Term of services",
        title: "Terms",
        path: `/term-of-services`
    },
    {
        id: 1,
        name: "Privacy Policy",
        title: "Privacy",
        path: `/privacy-policy`
    },
    {
        id: 0,
        name: "Home",
        title: "Home",
        path: `/`
    },
].sort((a, b) => a.id - b.id)