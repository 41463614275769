import React from 'react';
import Header from "../Header";
import {LayoutContainer} from "./styled";
import NavMenu from "../NavMenu";

const Layout = ({children}) => {
    return (
        <>
            <Header/>
            <NavMenu/>
            <LayoutContainer maxWidth="lg">
                {children}
            </LayoutContainer>
        </>
    );
};

export default Layout;