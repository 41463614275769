import React, {useState} from 'react';
import {NavItem, NavItemsWrapper, NavMenuContainer, NavMenuWrapper} from "./styled";
import {navMenu} from "./variables";
import {SubTitle} from "../SubTitle";

const NavMenu = () => {
    const [currentItem, setCurrentItem] = useState("Home")
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        if (open === false) {
            setOpen(true)
        } else {
            setOpen(!open)
        }
    }

    return (
        <NavMenuWrapper>
            <NavMenuContainer maxWidth="lg">
                <SubTitle onClick={handleOpen}>{currentItem}</SubTitle>
                <NavItemsWrapper open={open.toString()}>
                    {
                        navMenu.map((item) => {
                            return(
                                <NavItem fontWeight={(currentItem === item.title).toString()} key={item.id} onClick={() => setCurrentItem(item.title)} title={item.title}>{item.name}</NavItem>
                            )
                        })
                    }
                </NavItemsWrapper>
            </NavMenuContainer>
        </NavMenuWrapper>
    );
};

export default NavMenu;