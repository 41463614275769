import {Container, styled} from "@mui/material";
import {Form, FormButton} from "../../pages/Home/styled";

export const PageHeader = styled('header')({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    background: "#ffffff",
    color: "#313b44",
    padding: "20px 0",
});

export const PageHeaderContainer = styled(Container)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
})

export const LogoContainer = styled('div')({
    width: "100%",
    maxWidth: 200,
    "& > img": {
        width: "100%",
    }
})

export const LoginForm = styled(Form)({})
export const LoginButton = styled(FormButton)({
    margin: 0
})