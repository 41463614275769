export const dropDownValues = [
    {
        id: 0,
        name: "Packing guide for",
        values: [
            "travel to ",
            "business travel to "
        ],
    },
    {
        id: 1,
        name: "Ideas for",
        values: [
            "Birthday gift for ",
            "Anniversary gift for \n",
            "Wedding planning at \n",
            "Fashion clothing in \n",
            "Practice \n",
            "Hosting party at \n"
        ],
    },
]
