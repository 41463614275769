import React from 'react';
import {LoginButton, LoginForm, LogoContainer, PageHeader, PageHeaderContainer} from "./styled";
import Logo from "../../images/SimpleListAiLogo.png"
import {FormButton, FormInput} from "../../pages/Home/styled";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Box} from "@mui/material";
import {Title} from "../Title";

const Header = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "100%",
        maxWidth: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    };

    return (
        <PageHeader>
            <PageHeaderContainer maxWidth="lg">
                <LogoContainer>
                    <img src={Logo} alt=""/>
                </LogoContainer>
                <LoginButton onClick={handleOpen}>Login</LoginButton>
            </PageHeaderContainer>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Title>Login</Title>
                    <LoginForm>
                        <FormInput label="Email or phone" variant="standard"/>
                        <FormButton>Submit</FormButton>
                    </LoginForm>
                </Box>
            </Modal>
        </PageHeader>
    );
};

export default Header;